import '../scss/main.scss';

declare global {
    interface Window {
        UniCpo: {
            collectData: () => Record<string, string>;
        };
    }
}

const inquiryButtons = document.querySelectorAll('[data-action="open-inquiry-modal"]');

inquiryButtons.forEach((button) => {
    button.addEventListener('click', (event) => {
        event.preventDefault();

        const inquiryModal = document.querySelector('#poi-inquiry-modal');

        if (!inquiryModal) {
            return;
        }

        inquiryModal.classList.add('is-active');
    });
});

const inquiryModalCloseButtons = document.querySelectorAll('[data-action="close-inquiry-modal"]');

inquiryModalCloseButtons.forEach((button) => {
    button.addEventListener('click', (event) => {
        event.preventDefault();

        const inquiryModal = document.querySelector('#poi-inquiry-modal');

        if (!inquiryModal) {
            return;
        }

        inquiryModal.classList.remove('is-active');
    });
});

const inquiryModalForms = document.querySelectorAll('#poi-inquiry-modal form');

inquiryModalForms.forEach((form) => {
    form.addEventListener('submit', () => {
        const meta = window.UniCpo.collectData();

        Object.entries(meta).forEach(([key, value]) => {
            const input = document.createElement('input');
            input.type = 'hidden';
            input.setAttribute('name', `meta[${key}]`);
            input.setAttribute('value', value);
            form.appendChild(input);
        });
    });
});
